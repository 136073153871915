import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  uploadEBDRForm,
  S4RunHistoryDetails,
} from "../../@types/assetTypes/ap";
import InitiateInvoiceApi from "../../API/InvoicesApi";
import ResponseAPI from "../../API/index";
import SpinnerComponent from "../../components/Spinner";
import ToastComponent from "../../components/Toast";
import { s4RunHistoryStatusFilter } from "../../constants/s4upload";
import useStore from "../../store/useStore";
import { downloadFile, prependZeros } from "../../utils";

import DetailSection from "./DetailSection";
import HistorySection from "./HistorySection";
import InitiateSection from "./InitiateSection";

const S4Upload: React.FC = () => {
  const setLoader = useStore((state) => state.setLoader);
  const toastMessage = useStore((state) => state.toastMessage);
  const enableToast = useStore((state) => state.enableToast);

  const [inputVal, setInputVal] = useState<uploadEBDRForm>({
    file: undefined,
    remarks: "",
  });

  const [runHistory, setRunHistory] = useState<S4RunHistoryDetails[]>([]);
  const [detailsId, setDetailsId] = useState<string | number>("");

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [pageSize, setPageSize] = useState<number>(5);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);

  const [statusFilter, setStatusFilter] = useState<string>(
    s4RunHistoryStatusFilter.ALL,
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "desc" },
  ]);

  const pageNo = paginationModel.page;
  const pageNoRef = useRef(pageNo);
  const statusFilterRef = useRef(statusFilter);
  const filterModelRef = useRef(filterModel);
  const sortModelRef = useRef(sortModel);
  const buttonDisabled = !inputVal.file;
  const _runHistory = runHistory;

  // Note: Enable following code to do filtering in UI.
  // const _runHistory = runHistory.filter(({ status }) => {
  //   const dbStatus = status?.split(":")[0] || "";

  //   if (
  //     statusFilter === s4RunHistoryStatusFilter.ALL ||
  //     (statusFilter === s4RunHistoryStatusFilter.IN_PROGRESS &&
  //       includesStatus(s4RunHistoryStatusFilterGrouping.IN_PROGRESS, dbStatus)) ||
  //     (statusFilter === s4RunHistoryStatusFilter.COMPLETED &&
  //       includesStatus(s4RunHistoryStatusFilterGrouping.COMPLETED, dbStatus))
  //   ) {
  //     return true;
  //   } else return false;
  // });

  const goToUploadScreen = (): void => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      left: 0,
    });
    setDetailsId("");
  };

  const handleFileUpload = (file: File): void => {
    setInputVal((inputVal) => ({ ...inputVal, file }));
  };

  const closeFileUploaded = (): void => {
    setInputVal((inputVal) => ({ ...inputVal, file: undefined }));
  };

  const handleDescriptionChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    setInputVal((inputVal) => ({ ...inputVal, remarks: e.target.value }));
  };

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setLoader(true);

    const formData = new FormData();

    const data = {
      fileName: inputVal.file?.name,
      remarks: inputVal.remarks,
    };

    const json = JSON.stringify(data);

    const jsonData = new Blob([json], {
      type: "application/json",
    });

    formData.append("ebdrS4Request", jsonData);
    formData.append("file", inputVal.file || "");

    try {
      const request = InitiateInvoiceApi.uploadEBDR(formData);
      const response = await ResponseAPI.performRequest(request);

      if (response.data.errors.length !== 0) {
        const { message } = response.data.errors[0];

        setLoader(false);
        enableToast({
          pos: "top",
          message: message || "File not uploaded",
          type: "error",
        });

        return;
      }

      setInputVal({
        file: undefined,
        remarks: "",
      });
      setLoader(false);

      const { ebdrDetails }: { ebdrDetails: S4RunHistoryDetails } =
        response.data.data;

      setRunHistory((runHistory) => [ebdrDetails, ...runHistory]);

      enableToast({
        pos: "top",
        message: `File uploaded successfully, Run ID: ${ebdrDetails.id}`,
        type: "success",
      });
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getRunHistory = useCallback(
    async (
      _pageNo?: number,
      _status?: string,
      _filterModel?: GridFilterModel,
      _sortModel?: GridSortModel,
    ): Promise<void> => {
      setLoader(true);

      const pNo = _pageNo ?? pageNoRef.current;
      const status = _status ?? statusFilterRef.current;
      const fModel = _filterModel ?? filterModelRef.current;
      const sModel = _sortModel ?? sortModelRef.current;
      const idFilter = fModel?.items?.find(({ field }) => field === "id");

      const invoicesFilter = fModel?.items?.find(
        ({ field }) => field === "invoices",
      );

      const sourceInvoicesFilter = fModel?.items?.find(
        ({ field }) => field === "sourceInvoices",
      );

      const filterId: string = [undefined, null, ""].includes(idFilter?.value)
        ? ""
        : idFilter?.value;

      const filterInvoices: string =
        [undefined, null, ""].includes(invoicesFilter?.value) ||
        invoicesFilter?.value?.length === 0
          ? ""
          : invoicesFilter?.value?.join(",");

      const filterSourceInvoices: string =
        [undefined, null, ""].includes(sourceInvoicesFilter?.value) ||
        sourceInvoicesFilter?.value?.length === 0
          ? ""
          : sourceInvoicesFilter?.value
              ?.map((v: string) => prependZeros(v, 10))
              .join(",");

      const sortValue: string = sModel?.[0]?.field ? sModel[0].field : "id";
      const sortOrder: string = sModel?.[0]?.sort ? sModel[0].sort : "desc";

      try {
        const fId = filterId ? `run_id in (${filterId})` : "";

        const fInvoices = filterInvoices
          ? `SAP_INVOICES in (${filterInvoices})`
          : "";

        const fSourceInvoices = filterSourceInvoices
          ? `reference_document in (${filterSourceInvoices})`
          : "";

        const fStatus =
          status && status !== s4RunHistoryStatusFilter.ALL
            ? `status in (${status})`
            : "";

        const pageInfo = { pageNo: pNo, pageSize };

        const getRequest = InitiateInvoiceApi.getS4RunHistory(
          sortValue,
          sortOrder,
          fId,
          fInvoices,
          fSourceInvoices,
          fStatus,
          pageInfo,
        );

        const getResponse = await ResponseAPI.performRequest(getRequest);

        const {
          ebdrDetails = [],
          totalCount = 0,
        }: { ebdrDetails: S4RunHistoryDetails[]; totalCount: number } =
          getResponse.data.data;

        setRunHistory(ebdrDetails);
        setPaginationModel({ page: pNo, pageSize });
        setStatusFilter(status);
        setFilterModel(fModel);
        setSortModel(sModel);
        setTotalRowCount(totalCount);

        if (getResponse.data.errors.length > 0) {
          enableToast({
            pos: "top",
            message: getResponse.data.errors[0].message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoader(false);
    },
    [pageSize],
  );

  const deleteBDRs = (id: number) => async (): Promise<void> => {
    setLoader(true);

    try {
      const request = InitiateInvoiceApi.deleteBDRs(id);
      const response = await ResponseAPI.performRequest(request);

      if (response.data.errors.length === 0) {
        goToUploadScreen();
        enableToast({
          pos: "top",
          message: "BDRs deleted successfully",
          type: "success",
        });
      } else {
        enableToast({
          pos: "top",
          message: response.data.errors[0].message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  const createInvoice = (id: number) => async (): Promise<void> => {
    setLoader(true);

    try {
      const request = InitiateInvoiceApi.createInvoice(id);
      const response = await ResponseAPI.performRequest(request);

      if (response.data.errors.length === 0) {
        goToUploadScreen();
        enableToast({
          pos: "top",
          message: "Invoice created successfully",
          type: "success",
        });
      } else {
        enableToast({
          pos: "top",
          message: response.data.errors[0].message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  const fileDownload =
    (id: number, type: "vms" | "ebdr") =>
    async (fileName: string): Promise<void> => {
      setLoader(true);

      try {
        let request;

        if (type === "vms") {
          request = InitiateInvoiceApi.getDownloadVMSFile(id);
        } else {
          request = InitiateInvoiceApi.getDownloadEBDRFile(id);
        }

        const response = await ResponseAPI.performRequest(request);

        const newBlob = new Blob([response.data], {
          type: "application/octet-stream",
        });

        downloadFile(newBlob, fileName);
      } catch (error) {
        console.log(error);
        enableToast({
          pos: "top",
          message:
            error instanceof Error ? error.message : "Something went wrong!",
          type: "error",
        });
      }

      setLoader(false);
    };

  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  useEffect(() => {
    statusFilterRef.current = statusFilter;
  }, [statusFilter]);

  useEffect(() => {
    filterModelRef.current = filterModel;
  }, [filterModel]);

  useEffect(() => {
    sortModelRef.current = sortModel;
  }, [sortModel]);

  useEffect(() => {
    if (toastMessage.message === "") {
      enableToast({
        pos: "top",
        message: "",
        type: "",
      });
    }
  }, [toastMessage.message]);

  useEffect(() => {
    if (!detailsId) {
      getRunHistory();
    }
  }, [detailsId, getRunHistory]);

  useEffect(() => {
    if (detailsId) {
      const fetchRunHistoryDetails = async (): Promise<void> => {
        setLoader(true);

        try {
          const getRequest = InitiateInvoiceApi.getS4RunHistoryDetails(
            detailsId.toString(),
          );

          const getResponse = await ResponseAPI.performRequest(getRequest);

          if (getResponse.data.errors.length === 0) {
            setRunHistory((runHistory) => {
              const _runHistory = [...runHistory];
              const index = _runHistory.findIndex(({ id }) => id === detailsId);
              const newRunHistoryDetails = getResponse.data.data.ebdrDetail;

              _runHistory.splice(index, 1, {
                ..._runHistory[index],
                ...newRunHistoryDetails,
              });

              return _runHistory;
            });
          } else {
            enableToast({
              pos: "top",
              message: getResponse.data.errors[0].message,
              type: "error",
            });
          }
        } catch (error) {
          console.log(error);
        }

        setLoader(false);
      };

      fetchRunHistoryDetails();
    }
  }, [detailsId]);

  useEffect(() => {
    enableToast({
      pos: "top",
      message: "",
      type: "",
    });
  }, []);

  return (
    <>
      <SpinnerComponent />
      {toastMessage.message !== "" ? <ToastComponent /> : null}
      {detailsId === "" && (
        <InitiateSection
          inputVal={inputVal}
          handleFileUpload={handleFileUpload}
          handleDescriptionChange={handleDescriptionChange}
          handleSubmit={handleSubmit}
          buttonDisabled={buttonDisabled}
          closeFileUploaded={closeFileUploaded}
        />
      )}
      {detailsId === "" && (
        <HistorySection
          statusFilter={statusFilter}
          runHistory={_runHistory}
          totalRowCount={totalRowCount}
          pageNo={pageNo}
          pageSize={pageSize}
          filterModel={filterModel}
          sortModel={sortModel}
          getRunHistoryFunc={getRunHistory}
          onStatusFilterChange={(statusFilter) => {
            getRunHistory(0, statusFilter, undefined);
          }}
          onDetailsClick={setDetailsId}
          paginationModel={paginationModel}
          onPaginationModelChange={({ page, pageSize }) => {
            getRunHistory(page, undefined, undefined);
            setPageSize(pageSize);
          }}
          onFilterChange={(filterModel) => {
            getRunHistory(0, undefined, filterModel);
          }}
          onSortModelChange={(sortModel) => {
            getRunHistory(undefined, undefined, undefined, sortModel);
          }}
        />
      )}
      {detailsId !== "" && (
        <DetailSection
          details={_runHistory.find(({ id }) => id === detailsId)}
          onBackClick={goToUploadScreen}
          onDeleteBDRs={deleteBDRs(Number(detailsId))}
          onCreateInvoice={createInvoice(Number(detailsId))}
          onDownloadVMSFile={fileDownload(Number(detailsId), "vms")}
          onDownloadEBDRFile={fileDownload(Number(detailsId), "ebdr")}
        />
      )}
    </>
  );
};

export default S4Upload;
